import * as React from "react"

import homePage from "../components/Home/homePage"
import Seo from "../components/seo"
import PrivateRoute from "../components/privateRoute"
import ReduxWrapper from "../redux/reduxWrapper"

const IndexPage = props => {
  return (
    <React.Fragment>
      <Seo title="Home" />
      <PrivateRoute component={homePage} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
