import * as React from "react"

import Layout from "../Layout/layout"
import Seo from "../seo"
import ChatBox from "./chatbox"
import Feed from "./discussion/feed"
import "../../css/bootstrap-tagsinput.css"
import "../../css/bootstrap-select.css"
import "../../css/jquery.datetimepicker.css"
// import "../../css/chat-dock.css"
import "../../css/home.css"

const HomePage = () => {
  const discussionFeedRef = React.useRef(null)
  const [discussionFeeds, setDiscussionFeeds] = React.useState([
    { period: "today", monthsAgo: 0 },
    { period: "yesterday", monthsAgo: 0 },
    { period: "month", monthsAgo: 0 },
  ])
  const [monthsAgo, setMonthsAgo] = React.useState(1)

  React.useEffect(() => {
    // Function to load more data.
    const loadMoreData = async () => {
      // Load more data for the next month.
      setDiscussionFeeds([...discussionFeeds, { period: "month", monthsAgo: monthsAgo + 1 }])
      setMonthsAgo(monthsAgo + 1)
    }

    const handleScroll = () => {
      const container = discussionFeedRef.current
      if (container) {
        // Check if the user is near the bottom (adjust the threshold as needed).
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
          loadMoreData()
        }
      }
    }

    discussionFeedRef.current.addEventListener("scroll", handleScroll)

    return () => {
      discussionFeedRef.current?.removeEventListener("scroll", handleScroll)
    }
  }, [monthsAgo])

  const discussionFeedComponents = discussionFeeds.map((feed, index) => (
    <Feed key={index} period={feed.period} monthsAgo={feed.monthsAgo} />
  ))

  return (
    <Layout>
      <Seo title="Home" />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
                      <div className="col-12">
                        <div className="input-group filterDropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-bullseye"></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <span className="dropdown-item-text">== Saved Filtered Views ==</span>
                            </li>
                            <li>
                              <a className="dropdown-item createNewFilterView" href="#">
                                <i className="bi bi-plus"></i> Create New Filtered View
                              </a>
                            </li>
                          </ul>
                          <input
                            type="search"
                            className="form-control tableSearchMobile"
                            placeholder="Search"
                          />
                          <button className="btn btn-default">
                            <i className="bi bi-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 text-end swiftCloudTabs">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btnAddNewBlueDashed dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item btnQuickChatOpt" data-type="Chat" href="#">
                            <i className="bi bi-chat-fill"></i> Discussion
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnQuickChatOpt" data-type="Email" href="#">
                            <i className="bi bi-envelope-fill"></i> Email
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnQuickChatOpt" data-type="SMS" href="#">
                            <i className="bi bi-phone-fill"></i> SMS
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnQuickChatOpt" data-type="Phone" href="#">
                            <i className="bi bi-telephone-fill"></i> Phone Call
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-default dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-funnel-fill"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-funnel-fill"></i> Auto
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-funnel-fill"></i> Recent
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="timeline mt-3">
                      <div className="timeline_block mb-4">
                        <h2>Today</h2>
                        <ul>
                          <li>
                            <div className="eventTypeIconContainer">
                              <i className="bi bi-camera-video-fill timeline_type_icon videoAction" />
                            </div>
                            <div className="timeline_details">
                              <iframe
                                width="750"
                                height="290"
                                className="w-100"
                                src="https://www.youtube-nocookie.com/embed/4fXIoWptIvc?si=08aOCaIr_cFD5SJn"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                              />
                            </div>
                            <span className="timeline_timestamp">
                              Dec 5, 2023{" "}
                              <span className="timeline_time_detail">
                                (Tuesday) - <i className="bi bi-link-45deg" /> A SwiftLocal user clicked on
                                your website at <time>11:30 am</time>
                              </span>
                            </span>
                            <a href="#" className="btnTimelineTrash">
                              <i className="bi bi-trash-fill" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="timeline mt-3" ref={discussionFeedRef}>
                      {discussionFeedComponents}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
      <ChatBox />
    </Layout>
  )
}

export default HomePage
