import * as React from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { useEffect, useState } from "react"
import {
  dateTimeStringToShortDate,
  dateTimeStringToMonth,
  dateTimeStringToTime,
  dateTimeStringToDay,
} from "../../../services/globalHelpers"
import Message from "./message"

const Messages = ({ ownerId, discussionId, period, title }) => {
  const [messages, setMessages] = useState([])
  const [participants, setParticipants] = useState([])
  const [activeMessage, setActiveMessage] = useState(null)

  useEffect(() => {
    const loadMessagesAndParticipants = async (ownerId, discussionId) => {
      try {
        const responseMessages = await AxiosInstance.get(`/discussion/${ownerId}/messages/${discussionId}/`)
        const responseParticipants = await AxiosInstance.get(
          `/discussion/${ownerId}/participants/${discussionId}/`
        )

        if (responseParticipants.status === 202) {
          setParticipants(responseParticipants.data.participants)
        }

        if (responseMessages.status === 200) {
          setMessages(responseMessages.data.items)
        } else {
          console.error(responseMessages.status)
        }
      } catch (error) {
        console.error("There is some error, please check...", error)
      }
    }

    loadMessagesAndParticipants(ownerId, discussionId)
  }, [ownerId, discussionId])

  const onMessageOver = message => {
    setActiveMessage(message)
  }

  const activeMessageDate = () => {
    if (activeMessage) {
      return dateTimeStringToShortDate(activeMessage.createdAt)
    }

    if (messages.length) {
      return dateTimeStringToMonth(messages[messages.length - 1].createdAt)
    }

    return ""
  }

  const activeMessageHour = () => {
    if (activeMessage) {
      return dateTimeStringToTime(activeMessage.createdAt)
    }

    return ""
  }

  const activeMessageDay = () => {
    if (activeMessage) {
      return dateTimeStringToDay(activeMessage.createdAt)
    }

    return ""
  }

  const onMouseOut = () => {
    setActiveMessage(null)
  }

  const deleteMessage = async messageId => {
    try {
      const deleteMsgResponse = await AxiosInstance.delete(`/discussion/${ownerId}/messages/${messageId}/`)

      if (deleteMsgResponse.status === 202) {
        const newList = messages.filter(message => message.id !== messageId)
        setMessages(newList)
      } else console.log(deleteMsgResponse.status)
    } catch (error) {
      console.error("There is some error, please check the data: ", error)
    }
  }

  if (messages.length === 0) return null

  return (
    <>
      <div className="eventTypeIconContainer">
        <i className="bi bi-envelope-fill timeline_type_icon emailAction"></i>
      </div>
      <div className="timeline_details">
        <ul className="timline_emails">
          {messages.map(message => (
            <Message
              participants={participants}
              message={message}
              onMessageOver={onMessageOver}
              onMouseOut={onMouseOut}
              ownerId={ownerId}
              deleteMessage={deleteMessage}
              period={period}
              key={message.id}
            />
          ))}
        </ul>
      </div>
      <span className="timeline_timestamp">
        {activeMessageDate()}{" "}
        <span className="timeline_time_detail">
          ({activeMessageDay()}) at <time>{activeMessageHour()}</time> <i className="bi bi-link-45deg"></i>{" "}
          {title}{" "}
        </span>
      </span>
      <a href="#" className="btnTimelineTrash">
        <i className="bi bi-trash-fill"></i>
      </a>
    </>
  )
}
export default Messages
