import React, { useEffect, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import Messages from "./messages"

const Feed = ({ period, monthsAgo = 0 }) => {
  const [discussions, setDiscussions] = useState([])
  const [shown, setShown] = useState(false)

  useEffect(() => {
    const loadMsgFeed = async (period, monthsAgo) => {
      try {
        const response = await AxiosInstance.get(`/discussion/all?period=${period}&months_ago=${monthsAgo}`)
        if (response.status === 202) {
          const hasMessage = response.data.discussions.filter(discussion => discussion.lastMessageTime)
          setShown(hasMessage.length > 0)
          setDiscussions(response.data.discussions)
        } else {
          console.error("Unexpected response status.")
        }
      } catch (error) {
        console.error("There is some error. Please check the data:", error)
      }
    }
    loadMsgFeed(period, monthsAgo)
  }, [period, monthsAgo])

  const displayPeriod = period => {
    if (["today", "yesterday"].includes(period)) return period.charAt(0).toUpperCase() + period.slice(1)

    switch (monthsAgo) {
      case 0:
        return "This month"
      case "1":
        return "Last month"
      default:
        return `${monthsAgo} months ago`
    }
  }

  return (
    shown &&
    discussions.length !== 0 && (
      <div className="timeline_block mb-4">
        <h2>{displayPeriod(period)}</h2>
        <ul>
          {discussions.map(
            (discussion, index) =>
              discussion.lastMessageTime && (
                <li key={index}>
                  <Messages
                    discussionId={discussion.id}
                    ownerId={discussion.owner.id}
                    period={period}
                    monthsAgo={monthsAgo}
                    title={discussion.title}
                  />
                </li>
              )
          )}
        </ul>
      </div>
    )
  )
}

export default Feed
