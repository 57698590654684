import React from "react"
import { dateTimeStringToShortDate, dateTimeStringToTime } from "../../../services/globalHelpers"
import AxiosInstance from "../../../services/axiosInstance"

const Message = ({ message, onMessageOver, onMouseOut, participants, deleteMessage, period }) => {
  const messageDatetime = datetimeString => {
    if (["today", "yesterday"].includes(period)) return dateTimeStringToTime(datetimeString)

    return dateTimeStringToShortDate(datetimeString)
  }

  return (
    <li
      key={message.id}
      onMouseOver={() => onMessageOver(message)}
      onFocus={() => onMessageOver(message)}
      onMouseLeave={onMouseOut}
    >
      <div className="timline_email_user">
        <ul className="user-card-list">
          <li>
            <a href="SC_BS5/contact_detail.html">
              <img
                src={`${process.env.GATSBY_WRANGLER_URL}${participants[message.author].profilePic}`}
                alt=""
                className="user_avatar"
              />
              {participants[message.author].name}
            </a>
          </li>
        </ul>
      </div>
      <div className="timline_email_subject">
        <a href="#" className="btnOpenChatDockEmail" data-value="">
          {message.message}
        </a>
      </div>
      <div className="timline_email_time">{messageDatetime(message.createdAt)}</div>
      <div className="timline_email_action_container">
        <button
          type="button"
          className="btn btn-default btn-sm btnSMSReply"
          data-bs-toggle="tooltip"
          data-bs-title="Reply"
          data-bs-original-title=""
          title=""
        >
          <i className="bi bi-reply-fill"></i>
        </button>
        {message.author == localStorage.getItem("user_id") && (
          <button
            type="button"
            className="btn btn-danger btn-sm btnDeleteEmail"
            data-bs-toggle="tooltip"
            data-bs-title="Delete"
            data-bs-original-title=""
            title=""
            onClick={() => deleteMessage(message.id)}
          >
            <i className="bi bi-trash-fill"></i>
          </button>
        )}
        <button
          type="button"
          className="btn btn-default btn-sm btnAddNewSMS"
          data-bs-toggle="tooltip"
          data-bs-title="Add to Task"
          data-bs-original-title=""
          title=""
        >
          <i className="bi bi-plus-lg"></i>
        </button>
        <button
          type="button"
          className="btn btn-default btn-sm btnAddNewSMS"
          data-bs-toggle="tooltip"
          data-bs-title="Add to Calendar"
          data-bs-original-title=""
          title=""
        >
          <i className="bi bi-calendar-plus"></i>
        </button>
      </div>
    </li>
  )
}

export default Message
